import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M5310 7711 c0 -12 42 -103 51 -111 3 -3 12 -21 20 -41 8 -20 52 -116
99 -213 l85 -177 455 7 c605 9 832 -6 1001 -64 132 -46 288 -153 359 -247 19
-25 45 -58 57 -74 12 -15 33 -58 48 -97 14 -38 33 -82 42 -99 22 -42 32 -571
13 -719 -12 -97 -56 -240 -83 -269 -7 -6 -19 -25 -26 -40 -22 -43 -111 -141
-168 -186 -69 -56 -216 -127 -306 -151 -83 -21 -262 -38 -347 -34 -74 4 -89
-5 -70 -43 8 -15 18 -30 21 -33 3 -3 12 -23 21 -45 9 -22 55 -120 102 -217 61
-124 92 -178 104 -179 142 -8 489 76 604 148 21 13 40 23 43 23 7 0 114 69
164 107 77 57 203 194 262 283 79 120 141 259 184 415 9 33 22 78 28 100 8 27
12 168 12 445 0 390 -1 408 -23 495 -47 181 -61 223 -111 325 -79 162 -114
212 -223 319 -89 88 -108 103 -213 168 -99 62 -299 136 -440 163 -214 42 -208
42 -997 47 -528 3 -768 1 -768 -6z"/>
<path d="M6138 4223 c-16 -4 -18 -37 -18 -462 l0 -459 31 -6 c50 -10 278 -7
348 5 92 14 193 57 240 100 23 21 41 43 41 49 0 5 5 10 11 10 5 0 23 28 38 63
123 272 20 588 -215 664 -38 12 -88 27 -110 32 -40 11 -331 14 -366 4z m383
-173 c22 -6 57 -27 78 -45 21 -19 42 -31 46 -27 5 4 5 2 2 -4 -3 -6 3 -30 15
-53 29 -59 36 -197 15 -272 -39 -133 -123 -190 -279 -193 l-91 -1 1 300 c1
165 2 302 2 305 0 9 169 1 211 -10z"/>
<path d="M7741 4138 c-5 -13 -17 -47 -27 -77 -17 -47 -25 -57 -71 -83 -51 -29
-53 -31 -53 -74 0 -43 1 -44 33 -44 17 0 37 -4 43 -8 8 -5 13 -73 16 -212 3
-185 6 -209 25 -245 43 -82 104 -115 211 -115 40 0 85 7 106 15 34 15 36 17
36 65 0 27 5 52 10 55 6 3 10 1 10 -5 0 -25 44 -72 93 -100 44 -25 63 -30 124
-30 39 0 75 5 78 10 3 6 16 10 28 10 26 0 97 55 97 76 0 15 16 19 24 6 3 -5 6
-25 8 -45 2 -33 6 -38 35 -43 18 -3 49 -3 68 0 l35 7 0 249 c0 330 -9 368
-102 426 -58 37 -139 48 -244 34 -47 -6 -93 -15 -102 -20 -10 -5 -34 -16 -54
-24 -35 -14 -37 -17 -28 -41 27 -71 52 -115 61 -109 8 4 7 10 -2 16 -9 6 -5 7
12 2 17 -3 39 1 59 11 44 23 132 31 169 16 29 -13 47 -44 53 -91 3 -25 2 -25
-98 -32 -138 -9 -210 -30 -265 -76 -31 -26 -62 -101 -63 -154 -1 -26 -5 -50
-9 -54 -4 -4 -43 -9 -87 -12 l-80 -5 -15 37 c-12 29 -15 72 -13 209 l3 172 83
2 c103 2 112 5 108 40 -2 15 -4 48 -5 72 l-1 43 -92 -3 -93 -4 -3 78 -3 77
-55 0 c-47 0 -56 -3 -63 -22z m747 -563 c1 -17 -7 -50 -20 -74 -27 -53 -77
-76 -143 -66 -56 8 -66 16 -72 62 -10 76 50 113 177 110 53 -2 55 -3 58 -32z"/>
<path d="M7190 4010 c-30 -5 -82 -20 -115 -33 -67 -26 -68 -31 -34 -103 10
-22 19 -46 19 -52 0 -14 16 -16 24 -3 4 5 0 12 -6 15 -7 3 -3 3 10 0 12 -3 47
4 77 15 104 39 177 24 196 -40 19 -61 13 -65 -98 -72 -198 -12 -291 -69 -318
-194 -9 -42 12 -140 37 -176 11 -15 43 -41 71 -57 43 -25 63 -30 117 -30 90 0
201 44 183 73 -3 6 -1 7 5 3 6 -4 17 5 26 21 l15 28 7 -43 c4 -24 13 -50 21
-58 15 -14 61 -18 101 -8 l22 6 0 252 c0 276 -9 338 -55 386 -60 63 -179 90
-305 70z m173 -445 c-4 -53 -20 -91 -48 -109 -56 -37 -167 -27 -179 17 -11 41
-6 66 17 93 26 30 75 43 157 41 l55 -2 -2 -40z"/>
<path d="M3443 3063 c-25 -5 -32 -23 -9 -23 9 0 15 -15 18 -45 4 -59 3 -62
-35 -57 -44 5 -92 -35 -109 -90 -11 -36 -10 -45 12 -84 27 -53 56 -63 99 -38
30 18 31 18 45 -1 13 -19 14 -19 42 0 28 19 28 20 7 23 -23 3 -23 5 -23 163 0
121 -3 159 -12 158 -7 -1 -23 -4 -35 -6z m-12 -163 c28 -15 27 -109 -1 -135
-28 -25 -65 -13 -79 26 -16 45 -14 63 10 93 22 28 39 32 70 16z"/>
<path d="M4375 3058 c-32 -17 -55 -51 -55 -81 0 -15 -9 -35 -20 -44 -18 -16
-19 -18 -4 -23 26 -9 27 -161 2 -168 -38 -10 -15 -22 42 -22 58 0 84 15 38 22
-22 3 -23 8 -23 83 0 79 0 80 25 80 35 0 33 29 -3 33 -26 3 -27 6 -21 40 8 49
26 67 51 54 26 -14 59 4 42 24 -14 17 -46 18 -74 2z"/>
<path d="M7754 3015 c-8 -21 3 -48 19 -43 19 7 22 58 2 58 -8 0 -18 -7 -21
-15z"/>
<path d="M2225 3013 c-28 -7 -43 -23 -23 -23 15 0 17 -17 20 -122 3 -109 1
-122 -14 -126 -33 -8 -19 -20 32 -27 59 -8 105 1 143 27 37 26 38 86 3 121
-22 22 -23 27 -11 43 23 31 18 71 -11 90 -26 17 -103 27 -139 17z m109 -39
c34 -33 6 -88 -42 -82 -20 2 -26 9 -29 38 -2 19 -1 40 2 47 6 18 50 16 69 -3z
m18 -131 c19 -17 24 -59 9 -81 -4 -8 -27 -16 -50 -19 l-41 -6 0 67 0 66 32 -5
c18 -4 41 -13 50 -22z"/>
<path d="M6125 3013 c-30 -7 -41 -21 -22 -29 25 -9 25 -231 0 -249 -14 -10 -8
-13 36 -19 68 -9 114 1 146 30 34 31 34 84 1 117 -22 22 -23 25 -8 49 42 70
-44 127 -153 101z m109 -39 c9 -8 16 -21 16 -27 0 -23 -29 -55 -55 -59 -21 -4
-25 -1 -25 17 0 12 -3 37 -6 54 -6 29 -5 31 24 31 17 0 38 -7 46 -16z m19
-135 c37 -45 7 -99 -55 -99 -28 0 -28 0 -28 66 l0 67 33 -7 c17 -3 40 -16 50
-27z"/>
<path d="M7088 3013 c-16 -2 -28 -11 -28 -18 0 -9 6 -12 15 -9 13 5 15 -12 15
-120 0 -105 -2 -126 -15 -126 -8 0 -15 -4 -15 -9 0 -13 101 -25 138 -16 42 9
82 51 82 86 0 15 -11 41 -25 57 -24 28 -24 31 -9 61 14 29 14 34 -1 57 -26 40
-70 50 -157 37z m106 -39 c35 -35 10 -84 -43 -84 -18 0 -21 6 -21 50 0 46 2
50 24 50 14 0 32 -7 40 -16z m6 -119 c17 -9 30 -21 31 -28 0 -6 2 -22 5 -34 5
-29 -34 -56 -73 -51 -26 3 -28 7 -31 52 -5 79 9 92 68 61z"/>
<path d="M2870 2971 c-5 -21 -18 -42 -28 -46 -15 -7 -15 -8 3 -15 16 -6 20
-17 21 -61 1 -107 3 -118 25 -130 17 -9 28 -7 55 7 44 24 43 32 -3 30 l-38 -1
0 75 0 75 32 0 c18 1 35 4 39 7 13 14 -8 28 -42 28 -32 0 -34 2 -34 35 0 47
-17 44 -30 -4z"/>
<path d="M3160 3001 c0 -5 7 -11 16 -13 13 -2 16 -21 17 -121 0 -104 -2 -119
-19 -132 -17 -13 -14 -14 41 -14 52 0 58 1 43 13 -15 11 -18 31 -18 135 0 101
2 121 15 121 8 0 15 5 15 10 0 6 -25 10 -55 10 -30 0 -55 -4 -55 -9z"/>
<path d="M4990 3000 c0 -5 8 -10 19 -10 12 0 32 -21 57 -62 30 -50 38 -76 42
-125 4 -52 2 -63 -11 -63 -9 0 -19 -4 -22 -10 -4 -6 17 -10 54 -10 34 0 61 4
61 10 0 6 -9 10 -20 10 -18 0 -20 7 -20 56 0 38 6 64 19 82 10 15 28 46 40 70
12 23 28 42 36 42 8 0 15 5 15 10 0 6 -20 10 -45 10 -42 0 -61 -13 -33 -22 8
-3 3 -19 -15 -51 -15 -26 -29 -47 -31 -47 -2 0 -20 27 -40 60 -34 55 -40 60
-72 60 -19 0 -34 -4 -34 -10z"/>
<path d="M6783 3003 c-7 -3 -13 -15 -13 -28 0 -13 -8 -31 -17 -40 -15 -16 -15
-18 0 -26 13 -8 15 -19 11 -52 -7 -55 7 -127 27 -140 11 -7 26 -4 52 8 47 22
48 35 2 28 l-35 -6 -1 67 c-1 36 -2 72 -3 79 -1 7 12 12 34 12 25 0 36 5 38
18 3 14 -4 17 -38 17 -23 0 -39 4 -36 8 9 15 -8 59 -21 55z"/>
<path d="M2518 2924 c-54 -29 -73 -105 -43 -165 28 -53 90 -61 140 -17 34 29
31 42 -5 23 -57 -30 -91 -17 -105 38 l-7 27 66 0 c75 0 87 10 66 61 -21 49
-61 61 -112 33z m72 -33 c15 -30 14 -31 -40 -31 -54 0 -59 4 -33 32 21 24 60
23 73 -1z"/>
<path d="M2700 2920 c-31 -31 -25 -56 23 -90 75 -53 80 -62 45 -84 -20 -12
-68 10 -68 31 0 7 -4 13 -10 13 -5 0 -10 -14 -10 -30 0 -24 6 -33 27 -41 54
-20 113 14 113 68 0 21 -45 63 -82 76 -22 8 -24 43 -4 50 8 3 26 -3 39 -13 22
-18 26 -18 31 -4 16 43 -66 62 -104 24z"/>
<path d="M3583 2914 c-43 -33 -59 -90 -39 -137 15 -38 50 -67 80 -67 25 0 86
41 86 58 0 10 -5 9 -22 -2 -45 -32 -101 -12 -110 39 -3 18 3 20 59 23 71 3 91
18 72 54 -33 64 -73 75 -126 32z m71 -10 c9 -3 16 -14 16 -24 0 -14 -10 -19
-41 -22 -47 -4 -65 16 -34 38 21 15 35 17 59 8z"/>
<path d="M3796 2921 c-41 -27 -53 -51 -27 -51 11 0 25 9 31 21 17 31 64 23 68
-13 3 -24 -1 -28 -32 -34 -55 -10 -86 -39 -86 -80 0 -27 5 -37 26 -46 22 -10
31 -9 56 5 28 16 31 16 42 1 11 -15 16 -15 42 -2 24 12 33 13 54 2 14 -8 35
-14 48 -14 27 0 82 50 82 75 0 19 -35 54 -78 77 -40 21 -43 48 -5 48 14 0 34
-7 43 -15 16 -14 18 -14 23 5 12 47 -82 54 -116 9 -25 -34 -13 -58 48 -93 46
-28 59 -58 29 -70 -26 -10 -34 -7 -60 21 -25 27 -25 27 -31 5 -3 -13 -13 -22
-24 -22 -17 0 -19 8 -19 78 0 64 -4 82 -18 95 -24 22 -60 21 -96 -2z m74 -121
c0 -31 -22 -50 -60 -50 -31 0 -24 50 10 67 39 20 50 16 50 -17z"/>
<path d="M4462 2909 c-54 -55 -54 -129 0 -174 42 -36 73 -32 119 14 34 33 39
45 39 84 0 36 -6 51 -29 76 -40 43 -87 43 -129 0z m97 -25 c27 -35 28 -97 2
-123 -42 -42 -107 14 -98 84 9 65 59 86 96 39z"/>
<path d="M4670 2925 c-22 -12 -23 -14 -7 -20 14 -6 17 -20 17 -86 0 -64 -3
-79 -15 -79 -8 0 -15 -4 -15 -10 0 -5 25 -10 56 -10 34 0 53 4 49 10 -3 6 -13
10 -21 10 -10 0 -14 13 -14 50 0 83 42 140 69 93 8 -16 11 -15 21 11 8 23 8
31 -2 38 -20 12 -40 9 -61 -9 -18 -17 -19 -17 -28 0 -12 20 -16 21 -49 2z"/>
<path d="M5345 2929 c-37 -19 -60 -61 -60 -112 0 -42 4 -52 33 -78 37 -33 62
-36 102 -13 38 23 60 64 60 114 0 74 -71 121 -135 89z m74 -45 c28 -35 28 -97
1 -124 -27 -27 -36 -25 -72 10 -27 27 -30 36 -25 71 3 22 11 46 17 54 19 23
56 18 79 -11z"/>
<path d="M5533 2933 c-23 -4 -33 -23 -13 -23 6 0 10 -27 10 -62 0 -116 34
-158 101 -124 29 15 33 15 44 -1 8 -10 17 -13 26 -7 8 4 45 7 82 5 47 -2 67 1
62 8 -3 6 -13 11 -21 11 -20 0 -19 108 2 138 18 26 39 29 48 6 9 -24 26 -6 26
27 0 26 -3 29 -30 29 -18 0 -33 -6 -36 -15 -4 -8 -12 -15 -20 -15 -8 0 -14 7
-14 15 0 18 -1 18 -35 5 -24 -9 -25 -11 -11 -26 12 -11 16 -36 16 -91 0 -73
-1 -75 -22 -69 -13 3 -26 6 -30 6 -5 0 -8 40 -8 89 0 50 -3 92 -8 94 -11 8
-62 -4 -62 -14 0 -5 7 -9 15 -9 12 0 15 -14 15 -63 0 -70 -15 -94 -61 -99 -24
-3 -24 -2 -29 91 -3 52 -9 95 -15 96 -5 1 -20 0 -32 -2z"/>
<path d="M6408 2917 c-50 -42 -62 -101 -33 -157 29 -56 83 -65 133 -24 35 29
34 53 -1 29 -46 -32 -107 -6 -107 47 0 15 9 18 58 18 71 0 87 9 78 44 -16 64
-79 85 -128 43z m80 -37 c3 -24 1 -25 -43 -22 -48 4 -54 11 -29 37 25 25 68
16 72 -15z"/>
<path d="M6600 2920 c-37 -37 -16 -83 48 -109 37 -15 48 -39 28 -59 -19 -19
-66 -7 -73 18 -8 31 -23 24 -23 -10 0 -24 6 -33 26 -40 40 -15 61 -12 89 14
40 38 32 74 -27 111 -52 33 -64 65 -24 65 13 0 29 -7 37 -16 11 -14 13 -14 22
2 22 40 -66 61 -103 24z"/>
<path d="M7343 2933 c-26 -5 -31 -23 -8 -23 12 0 15 -16 15 -84 0 -66 4 -88
17 -101 19 -20 66 -14 94 11 16 14 18 14 24 -5 7 -23 25 -27 42 -10 9 9 23 9
55 0 68 -19 118 10 118 68 0 19 -47 62 -82 74 -10 4 -18 16 -18 27 0 25 35 27
61 4 16 -14 18 -14 24 6 9 27 6 30 -39 37 -45 7 -86 -17 -86 -51 0 -25 44 -70
80 -81 24 -8 27 -53 4 -62 -19 -7 -64 16 -64 34 0 20 -17 15 -23 -7 -3 -11
-12 -20 -21 -20 -14 0 -16 15 -16 96 l0 96 -27 -7 c-34 -8 -54 -25 -30 -25 14
0 17 -11 17 -69 0 -67 -1 -69 -32 -84 -55 -27 -61 -17 -58 90 1 55 -6 94 -18
92 -4 -1 -17 -4 -29 -6z"/>
<path d="M7740 2929 c-23 -9 -23 -11 -7 -20 24 -14 26 -169 2 -169 -8 0 -15
-4 -15 -10 0 -5 24 -10 53 -10 45 1 50 2 35 14 -15 11 -18 30 -18 110 0 102
-1 104 -50 85z"/>
<path d="M7860 2929 c-23 -9 -23 -11 -7 -20 24 -14 26 -169 2 -169 -8 0 -15
-4 -15 -10 0 -5 23 -10 50 -10 28 0 50 5 50 10 0 6 -7 10 -15 10 -11 0 -15 13
-15 53 0 56 16 89 52 108 33 18 48 -12 48 -93 0 -54 -3 -68 -15 -68 -8 0 -15
-4 -15 -10 0 -5 23 -10 50 -10 28 0 50 5 50 10 0 6 -7 10 -15 10 -12 0 -15 15
-15 78 0 118 -40 154 -109 97 l-30 -26 -3 22 c-4 27 -15 31 -48 18z"/>
<path d="M8132 2906 c-34 -32 -37 -39 -36 -87 0 -45 5 -56 32 -81 40 -35 65
-35 109 -2 38 29 45 54 8 30 -36 -24 -70 -20 -94 10 -35 45 -28 53 47 56 64 3
67 4 70 29 2 14 -6 37 -18 52 -30 38 -70 36 -118 -7z m86 -8 c25 -25 13 -38
-33 -38 -30 0 -45 4 -45 13 0 19 21 37 45 37 12 0 26 -5 33 -12z"/>
<path d="M8333 2920 c-41 -37 -29 -68 41 -105 29 -16 36 -25 34 -45 -4 -37
-41 -38 -74 -3 l-26 28 4 -35 c3 -31 7 -36 41 -44 52 -13 92 16 92 65 0 30 -6
38 -50 67 -27 19 -50 39 -50 45 0 19 45 20 65 2 17 -15 19 -15 25 5 9 27 5 31
-42 37 -30 4 -43 0 -60 -17z"/>
<path d="M8503 2923 c-34 -23 -30 -63 10 -90 17 -12 40 -25 50 -29 12 -4 17
-15 15 -33 -3 -22 -7 -26 -33 -24 -17 1 -37 12 -46 24 l-16 22 -6 -24 c-9 -34
15 -54 64 -54 31 0 46 6 61 24 31 39 20 71 -37 109 -27 19 -50 39 -50 45 0 19
45 20 65 2 16 -15 18 -15 24 2 15 38 -58 57 -101 26z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
